@media (max-width: 599px){.margin-bottom-large {
  margin-bottom: var(--spacing-medium);
}

h1,
.h1 {
  font-size: 5rem;
}

h2,
.h2 {
  font-size: 4rem;
}

h3,
.h3 {
  font-size: 3rem;
}

h4,
.h4 {
  font-size: 2.4rem;
}

h5,
.h5 {
  font-size: 2rem;
}

h6,
.h6 {
  font-size: 1.6rem;
}

.big-title {
  margin: 5rem 0;
}

.btn-full-xs {
  width: 100%;
  text-align: center;
}

.btn-full-xs+.btn-full-xs {
  margin-top: calc(var(--gap)*2);
}

#social-menu {
  margin-bottom: calc(var(--gap)*2);
}

.card-glossary {
  --grid-columns: 1fr;
  --grid-rows: repeat(2, auto);
}

.card-glossary .glossary-mention {
  display: block;
  margin-top: 1.2rem;
}

.glossary-term-container {
  --grid-columns: 1fr;
  --grid-rows: auto;
  --marg-b: 4rem;
}

.in-footer .author-content {
  margin-top: 6rem;
  margin-left: -14rem;
  padding: 8rem 2rem 2rem;
}}@media (max-width: 1029px){.ico-background-header {
  display: none;
}

.flower-background-header.top-right {
  --size-ico: 30rem;
  --ico-top: -15.3rem;
  --ico-right: -14rem;
}

.open-menu {
  font-size: 3rem;
}

#primary-menu {
  position: fixed;
  width: 100vw;
  top: 6.8rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform .2s;
  z-index: 10;
}

.menu-menu-principal-container a,
.menu-menu-principal-container label {
  display: block;
}

.menu-menu-principal-container li:not(:last-child) {
  border-bottom: solid 1px #e0e0e0;
}

.menu-menu-principal-container a,
.menu-menu-principal-container label {
  position: relative;
  padding: 2rem 1.6rem;
}

.menu-menu-principal-container a .ico,
.menu-menu-principal-container label .ico {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.menu-menu-principal-container .sub-menu {
  margin-bottom: 2rem;
}

.menu-menu-principal-container .sub-menu li:not(:last-child) {
  margin-bottom: 2rem;
}

.menu-menu-principal-container .sub-menu a {
  padding: 0;
}

#open-menu:checked~.open-menu:before {
  content: "fermer";
}

#open-menu:checked~#primary-menu {
  transform: translateX(0);
}

.open-submenu~a {
  display: none;
}

.open-submenu:checked~label {
  color: #e6513a;
}

.open-submenu:checked~label .ico:before {
  content: "replier";
}

.open-submenu:checked~.sub-menu {
  display: block;
}

.btn-contact,
.btn-search {
  margin: 0;
}

.header-btn {
  margin-top: 4rem;
  gap: var(--gap);
}

.card-description {
  display: none;
}

.animation .ico-background-header {
  display: none;
}

.in-header {
  display: none;
}}@media (max-width: 1279px){.custom-section.flower-bottom-left {
  padding-bottom: 17rem;
}

.alphabetical-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.alphabetical-nav::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
  width: 0px;
  height: 0px;
  color: none;
}}